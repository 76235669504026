<template>
  <div>
    <el-dialog
      class="scjb_Dialog Dialog_style1"
      :visible.sync="visible"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title">
        {{ type === 'default' ? '导出数据' : '批量导出数据' }}
      </div>
      <div class="cont">
        <span class="export-txt" v-if="type === 'custom'"
          >将为您导出{{ total }}条数据</span
        >
        <div v-else class="export-data pos-r">
          <p>导出条数</p>
          <el-slider v-model="data" :max="total" show-input class="pos-r" />
          <p class="num flex space-between">
            <span>0</span>
            <span>{{ total }}</span>
          </p>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" round @click="confirmed()">{{
          confirm
        }}</el-button>
        <el-button round @click="canceled()">{{ cancel }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'export-dialog',
  props: {
    confirm: {
      type: String,
      default: '确定'
    },
    cancel: {
      type: String,
      default: '取消'
    }
  },
  data() {
    return {
      visible: false,
      data: '',
      total: 0,
      type: 'default'
    };
  },
  watch: {},
  mounted() {},
  methods: {
    show(data = '', type = 'default', total = 0) {
      this.type = type;
      this.total = total;
      this.data = data;
      this.visible = true;
    },
    confirmed() {
      const data = {
        type: this.type,
        data: this.data
      };

      if (data.type === 'default') {
        if (data.data <= 0) {
          this.$message.warning('暂无可供导出的数据。');
          return false;
        }
      }

      this.$emit('must', data);
      this.visible = false;
    },
    canceled() {
      this.$emit('cancel');
      this.visible = false;
    }
  }
};
</script>
<style scoped>
.scjb_Dialog .mod-title {
  margin-left: 20px;
}
.export-txt {
  font-size: 14px;
}
.export-data {
  width: 350px;
}
.export-data p {
  font-size: 14px;
  margin-left: -10px;
  line-height: 30px;
}
.export-data >>> .el-slider {
  height: 80px;
}
.export-data >>> .el-slider__runway.show-input {
  width: 100%;
}
.export-data >>> .el-slider__input {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.export-data .num {
  position: absolute;
  left: 10px;
  top: 55px;
  z-index: 10;
  right: 0;
}
</style>
