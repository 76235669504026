<template>
  <div>
    <el-dialog :close-on-click-modal="false"
      :visible.sync="listDialog.visible"
      class="common-big-dialog mod-list-dialog">
      <div slot="title" class="header-title">
        <div class="mod-title">
          <!-- <span v-if="showTotal">信息列表 / {{ listData.phonyTotal ? listData.phonyTotal : listData.page.total }}条</span> -->
          <span v-if="showTotal">信息列表</span>
          <span v-else>信息列表</span>
          <div class="f-r mar_t-7">
            <el-button
              icon="iconfont icon-xiazai"
              class="dc-excel bd-n"
              v-loading="exportLoading"
              @click="exportData()">导出excel</el-button>
          </div>
        </div>
      </div>
      <div v-loading="listDialog.loading" class="listDialogVisible h-100">
        <el-form :inline="true" ref="form" :model="where" label-width="80px">
          <el-form-item label="情感" class="demo-form-inline w-50 list-form">
            <el-radio-group v-model="where.emotion" @change="emotionChange">
              <el-radio label="all" value="all">全部</el-radio>
              <el-radio label="negative" value="negative">敏感</el-radio>
              <el-radio label="positive" value="positive">非敏感</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="相似合并" class="demo-form-inline w-50 list-form">
            <el-radio-group v-model="where.similar" @change="similarChange">
              <el-radio label="no">不合并</el-radio>
              <el-radio label="yes">合并</el-radio>
            </el-radio-group>
            <el-tooltip class="item" effect="dark" content="提供时间跨度为30天及以内的相似合并" placement="bottom">
              <i class="iconfont icon-tishi" style="position: relative;left: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="排序方式" class="demo-form-inline w-50 list-form">
            <el-select v-model="listData.order" @change="whereChange" class="w-140 f-r">
              <el-option value="intelligence" label="智能排序"></el-option>
              <el-option value="importance" label="重要度排序"></el-option>
              <el-option value="default" label="相关度排序"></el-option>
              <el-option value="desc" label="发布时间降序"></el-option>
              <el-option value="asc" label="发布时间升序"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="scroll-area mar-t-10">
          <!-- <vue-scroll> -->
            <el-table
              id="articlescroll"
              :data="listData.list"
              border
              class="w-100 mar-t-10">
              <el-table-column
                label="标题">
                <template slot-scope="scope">
                  <div class="item pad-lr-10">
                    <h3 class="w-100 ov-h">
                      <el-button type="primary" class="fmg" v-if="scope.row.emotion === 'positive'">非敏感</el-button>
                      <el-button type="primary" class="mg" v-else-if="scope.row.emotion === 'negative'">敏感</el-button>
                      <span class="icon-yuanfabiaoqian" v-if="scope.row.category == '微博' && scope.row.isforward == 0">原</span>
                      <span class="icon-zhuanfabiaoqian" v-if="scope.row.category == '微博' && scope.row.isforward == 1">转</span>
                      <a class="title" v-html="scope.row.title" @click="showDetail(scope.row.rowkey)"></a>
                    </h3>
                    <p class="summary" v-html="scope.row.description"></p>
                    <el-row class="lh-30">
                      <el-col :span="18">
                        <p class="info">
                          <span>媒体类型：{{ scope.row.category }}</span>
                          <span>信源地区：{{ msgArea(scope.row) }}</span>
                          <span v-if="scope.row.hasOwnProperty('aboutWord')">
                            涉及词：{{ scope.row.aboutWord.length >= 1 ? scope.row.aboutWord.join('、') : '--' }}
                          </span>
                        </p>
                      </el-col>
                      <el-col :span="6">
                        <ul class="ico-list">
                          <li>
                            <i
                              class="iconfont icon-fuzhi"
                              :class="{'iconfont': true, 'icon-fuzhi': true, 'selected': scope.row.clipboard}"
                              @click="scope.row.clipboard = !scope.row.clipboard">
                            </i>
                            <ul class="copy-dropdown" v-show="scope.row.clipboard">
                              <li class="selected clipboard" :data-clipboard-text="scope.row.source_url" @click="copyData(scope.row, true)">拷贝地址</li>
                              <li class="clipboard" :data-clipboard-text="scope.row.copyText" @click="copyData(scope.row)">一键复制</li>
                            </ul>
                          </li>
                          <li v-show="listData.params.hasOwnProperty('plan_id')" v-if="!scope.row.markLoading">
                            <el-tooltip class="item" effect="dark" content="屏蔽信息" placement="bottom">
                              <i
                                  :class="{'iconfont': true, 'icon-pingbi': true, 'selected': scope.row.mark}"
                                  @click="docMarkAction(scope.row)">
                              </i>
                            </el-tooltip>
                          </li>
                          <li class="mar-t-5" v-if="scope.row.markLoading">
                            <i class="iconfont icon-Loading Rotation"></i>
                          </li>
                          <li>
                            <span class="cur-p">
                              <i
                                  :class="{'iconfont': true, 'icon-shoucang': true, 'pos-r': true, 'selected': scope.row.joinMaterial.visible}"
                                  @click="scope.row.joinMaterial.visible = !scope.row.joinMaterial.visible">
                              </i>
                            </span>
                            <favorite-dialog
                                    :ids="scope.row.rowkey"
                                    @done="scope.row.joinMaterial.visible = false"
                                    v-if="scope.row.joinMaterial.visible"
                                    :modal="false" />
                          </li>
                          <li>
                            <el-tooltip class="item" effect="dark" content="查看原文" placement="bottom">
                              <i class="iconfont icon-lianjie" @click="target(scope.row.source_url)"></i>
                            </el-tooltip>
                          </li>
                        </ul>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="similar"
                label="相似文章数"
                width="80">
                <template slot-scope="scope">
                  <p class="t-c">{{ scope.row.hasOwnProperty('similar_num') ? scope.row.similar_num : 1 }}</p>
                </template>
              </el-table-column>
              <el-table-column
                prop="source"
                label="来源"
                width="80">
                <template slot-scope="scope">
                  <p class="t-c line-four">{{ scope.row.category === '微信' ? scope.row.author : scope.row.source_name }}</p>
                </template>
              </el-table-column>
              <el-table-column
                prop="author"
                label="作者"
                width="80">
                <template slot-scope="scope">
                  <p class="t-c">{{ scope.row.author.length >= 1 ? scope.row.author : '--' }}</p>
                </template>
              </el-table-column>
              <el-table-column
                prop="time"
                label="时间"
                width="90">
                <template slot-scope="scope">
                  <span>{{ scope.row.published }}</span>
                </template>
              </el-table-column>
            </el-table>
          <!-- </vue-scroll> -->
        </div>
        <el-row class="button-group">
          <el-pagination
            :hide-on-single-page="true"
            @current-change="pageChange"
            layout="prev, pager, next, jumper"
            :page-size="pageSize"
            :current-page="currentPage"
            :total="listData.page.total >= 5000 ? 5000 : listData.page.total" />
        </el-row>
      </div>
    </el-dialog>
    <export-dialog ref="export" @must="must" />
    <article-detail ref="detail" :params="listData.params" :materials="materialData" />
  </div>
</template>
<script>
  import favoriteDialog from '@components/common/favorite-dialog.vue'
  import articleDetail from '@components/common/article-detail.vue'
  import { shortcut } from "@/api/message";
  import {lists as materialList, add_item} from "@/api/material";
  import { getAuthToken, queryString, formatMsgArea, handleDownload } from "@/utils/helpers";
  import { docMark, cancelDocMark } from '@/api/message'
  import { mapGetters } from 'vuex'
  import Clipboard from 'clipboard'
  import ExportDialog from '@components/common/export-dialog.vue'
  const { _ } = window
  export default {
    name: "dialog-article-list",
    props: ["materials"],
    data() {
      return {
        exportLimit:0,
        listDialog: {visible: false, loading: false, loadMoreDisabled: false, moreLoading: false},
        listData: {list: [], page: {current: 0, size: 0, total: 0}, order: "intelligence", params: {}, api: null, phonyTotal: null},
        handleData: false,
        materialData: [],
        showTotal: true,
        exportLoading: false,
        currentPage: 1,
        pageSize: 10,
        where: {
          emotion: 'negative',
          similar: 'no'
        }
      }
    },
    watch: {
      "materials": function(data) {
        this.materialData = data
      }
    },
    mounted() {
      this.listData.list = []
    },
    computed: {
      ...mapGetters([
        'user'
      ])
    },
    components: {
      articleDetail,
      favoriteDialog,
      ExportDialog
    },
    methods: {
      target(url) {
        window.open(url, '_blank');
      },
      pageChange(page){
        this.currentPage = page
        this.requestListData(this.listData.api)
      },
      requestListData(api, args = null, phonyTotal = null) {
        const _this = this
        var params = args
        if (params) {
          this.listData.phonyTotal = phonyTotal // 子组件传参数据总量
          this.listData.params = params
          this.listData.api = api
          this.listData.page.current = 1
          this.listDialog.visible = true
          this.listDialog.loadMoreDisabled = false
          this.where.emotion = params.hasOwnProperty('emotion') ? params.emotion : 'all'
          this.where.similar = params.hasOwnProperty('similar') ? params.similar : 'no'

          this.currentPage = 1
        } else {
          params = this.listData.params
        }

        if (params.hasOwnProperty('span')) {
          const span = params.span.split('~')
          const end = new Date(span[1]).getTime()
          const begin = new Date(span[0]).getTime()
          const days = Math.ceil((end - begin) / (86400 * 1000))

          if (days > 30 && params.similar !== 'no') {
            // 30日阀值 超过禁止合并查询
            params.similar = 'no'
            this.where.similar = 'no'
            this.$message.warning('仅提供时间跨度为30天及以内的相似合并')
            return false
          }
        }

        this.showTotal = params.hasOwnProperty('simhash') ? false : true // 相似文章列表需要隐藏相似数
        params['size'] = this.pageSize
        params['page'] = this.currentPage
        params['order'] = this.listData.order

        this.listDialog.loading = true
        window.$axios.get(api, {params: params}).then(res => {
          if (res.data.state) {
            let result = res.data.data
            if (_.size(result.list) < 1) {
              this.$message.warning("暂无更多")
              this.listDialog.loadMoreDisabled = true
            }

            const emotionMap = {'negative': '敏感', 'positive': '非敏感'}
            _(result.list).forEach((item, i) => {
              result.list[i].joinMaterial = {visible: false}
              result.list[i].mark = item.hasOwnProperty('uid') && item.uid.indexOf(_this.user.id) !== -1 // 文档是否被标记
              result.list[i].markLoading = false
              result.list[i].clipboard = false
              const aboutWord = item.hasOwnProperty('aboutWord') ? item.aboutWord.length >= 1 ? `涉及词：${item.aboutWord.join(',')}` : '涉及词：--' : ''
              result.list[i].copyText =
                      `标题：${item.title}\r\n\r\n摘要：${item.description}\r\n\r\n原文链接：${item.source_url}\r\n\r\n发布时间：${item.published}\r\n\r\n来源：${item.source_name}\r\n\r\n作者：${item.author ? item.author : '--'}\r\n\r\n情感属性：${emotionMap[item.emotion]}\r\n\r\n${aboutWord}`

            })
            // this.listData.list = this.listData.list.concat(result.list)
            this.listData.list = result.list
            this.listData.page = result.page
            this.listDialog.moreLoading = false
            this.listDialog.loading = false
            if (!this.materials && this.materialData.length < 1) {
              this.loadMaterial()
            }

            this.$nextTick(() => {
              // 返回顶部
              articlescroll.scrollIntoView()
            })
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(e => {
          window.console.error(e)
          this.$message.error("加载数据列表错误")
        })
      },
      docMarkAction (item) {
        item.markLoading = true
        if (item.mark) {
          // 取消文档标记
          cancelDocMark({rowkey: item.rowkey}).then(res => {
            if (res.data.state) {
              this.$message('信息恢复为精准信息')
              item.mark = false
            } else {
              this.$message.error(res.data.error)
            }
            item.markLoading = false
          }).catch(e => {
            item.markLoading = false
            window.console.error(e)
          })
        } else {
          // 进行文档标记
          docMark({plan_id: this.listData.params.plan_id, rowkey: item.rowkey, msg: ''}).then(res => {
            if (res.data.state) {
              this.$message({
                type: 'success',
                dangerouslyUseHTMLString: true,
                message: this.renderMarkMsgHtml()
              })
              item.mark = true
            } else {
              this.$message.error(res.data.error)
            }
            item.markLoading = false
          }).catch(e => {
            item.markLoading = false
            window.console.error(e)
          })
        }
      },
      renderMarkMsgHtml() {
        return (<p><span class="cor-67c">信息已被屏蔽成功。</span><span class="cur-p underline cor-606" on-click={ () => {this.$router.push({name: 'shield', params: {}})} }>点击查看</span></p>);
      },
      copyData (item, isUrl = false)
      {
        // data-clipboard-text
        const _this = this
        const clipboard = new Clipboard('.clipboard')
        clipboard.on('success', function () {
          const text = isUrl ? `成功复制链接：${item.source_url}` : '复制成功'

          _this.$message.success(text)
          item.clipboard = false
          clipboard.destroy()
        })
        clipboard.on('error', function () {
          _this.$message.error('复制失败')
        })
      },
      msgArea(row) {
        return formatMsgArea(row);
      },
      showInput() {
        if (this.listData.page.total <= 0) {
          this.$message.warning("当前数据条数为0")
          return false
        }
        this.$refs.input.show()
      },
      indexBuildBrief(submitObj) {
        const { name, type } = submitObj;
        if (_.size(name) < 1 || _.size(name) > 10) {
          this.$message.warning("简报名称1~10个字符")
          return false
        }
        const params = JSON.parse(JSON.stringify(this.listData.params));
        params["name"] = name;
        params['type'] = type;
        params["op"] = "briefing";
        this.handleData = false
        this.$message.warning("简报生成中...")
        Object.assign(params, submitObj);
        shortcut(params).then(res => {
          if (res.data.state) {
            this.$message.success("生成简报成功，请在历史简报清单中下载")
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(res => {
          this.$message.error("生成简报错误，服务错误")
        })
      },
      exportData() {
        this.exportLimit = 0
        this.$refs['export'].show(0, 'default', this.listData.page.total > 5000 ? 5000 : this.listData.page.total)
      },
      must(data) {
        this.exportLimit = data.data
        this.confirmExport();
      },
      confirmExport(){
        if (this.exportLoading) {
          return false
        }
        if (this.listData.page.total <= 0) {
          this.$message.warning("当前数据条数为0")
          return false
        }
        const params = JSON.parse(JSON.stringify(this.listData.params));
        params.op = 'down';
        params.token = getAuthToken();

        try {
          if (params.hasOwnProperty('focus') && params.focus.hasOwnProperty('must')) {
            params.focus = JSON.stringify(params.focus)
          }
        } catch (e) {
          window.console.error(e)
        }
        params.size = this.exportLimit
        const query = decodeURIComponent(queryString(params, true))
        handleDownload(`${window.service.api}/message/excel${query}`, 'GET', `${window.$moment().format("YYYY-MM-DD")}.xlsx`, null, this.exportLimit, () => {
          this.exportLoading = false
        })
      },
      emotionChange(emotion) {
        this.where.emotion = emotion
        this.whereChange()
      },
      similarChange(similar) {
        this.where.similar = similar
        this.whereChange()
      },
      whereChange() {
        const { where } = this
        this.listData.params.emotion = where.emotion
        this.listData.params.similar = where.similar
        this.requestListData(this.listData.api, this.listData.params)
      },
      addRowMaterialAction(material, id) {
        if (!material.selectedId) {
          this.$message.warning("请选择素材库")
          return false
        }
        material.loading = true
        add_item({id: material.selectedId, ids: id}).then(res => {
          if (res.data.state) {
            this.$message.success("添加成功")
          } else {
            this.$message.error(res.data.error)
          }
          material.loading = false
          material.visible = false
        }).catch(res => {
          this.$message.error("添加素材失败，服务错误")
        })
      },
      showDetail(rowkey) {
        let pid = this.listData.params.plan_id ? this.listData.params.plan_id : null
        this.$refs.detail.showDetail(rowkey, 'article-list', null, pid)
      },
      loadMaterial() {
        materialList().then(res => {
          if (res.data.state) {
            var result = res.data.data
            this.materialData = result
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(res => {
          this.$message.error("加载收藏夹失败，服务错误")
        })
      }
    }
  }
</script>
<style scoped>
.mod-list-dialog >>> .el-dialog__header{padding-right:50px;padding-top: 20px;}
.mod-list-dialog .dc-excel{float: right;font-size: 12px;margin-right: 10px;margin-top:8px;}
.mod-list-dialog .dc-excel:hover{background: none;}
.mod-list-dialog >>> .icon-xiazai{margin-right: 5px;float: left;}
/* 列表内容区块 */
.mod-list-dialog li.item {padding:15px 20px 10px 0;}
.mod-list-dialog li.item:not(:last-child){border-bottom: 1px solid #f2f2f2;}
.item h3 {line-height:24px;}
.item .title {font-weight:600;font-size: 14px;width: calc(100% - 200px);overflow: hidden;white-space: nowrap;cursor: pointer;text-overflow:ellipsis;float: left;}
.item li {float: right;margin-left: 10px;}
.item .summary {font-size: 12px;line-height:24px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient: vertical;-webkit-line-clamp: 2;color: #414141;margin-top: 10px;}
.item .info {line-height:30px;font-size: 12px;color: #707070;}
.item .info span {margin-right: 20px;}
.common-big-dialog .scroll-area{padding-bottom: 10px;height:calc(100% - 140px)}
/* 迭代2.0.5  */
.common-big-dialog >>> .el-table--small th{padding:0;font-size: 14px;color:#414141;background: #F3F5FC;}
.common-big-dialog >>> .el-table th>.cell{padding: 0;text-align: center;font-weight: 600;line-height: 45px;}
.common-big-dialog >>> .title{float:left;font-weight: 600;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;max-width:calc(100% - 165px);width:auto;padding-right:10px;}
.common-big-dialog .summary{display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:3;margin:10px 0 5px;}
.common-big-dialog .ico-list > li {float: right;margin-left: 10px;}
.common-big-dialog .info span{font-size: 12px;color:#707070;line-height: 30px;text-align:left;}
.common-big-dialog .info span
.common-big-dialog >>> .el-table td div,.common-big-dialog >>> .el-table td .t-c{color:#707070;}
.common-big-dialog >>> .el-table td .cell,.common-big-dialog >>> .el-table td .t-c{color:#707070;overflow: visible;}
.common-big-dialog >>> .el-table,.common-big-dialog >>> .el-table__body-wrapper{overflow: visible;}
.list-form {
  width: 48%;
}
.list-form >>> .el-form-item__label {
  font-weight: 600;
}
/* 拷贝下拉列表 */
.copy-dropdown{position: absolute;right:-10px;top:30px;z-index:10;text-align: center;background:rgba(255,255,255,1);box-shadow:0px 2px 12px 0px rgba(0,0,0,0.06);border:1px solid rgba(228,231,237,1);padding:5px 0;width:80px;border-radius: 4px;}
.copy-dropdown li{line-height: 30px;height: 30px;font-size: 12px;color:#606266;cursor: pointer;float: none;margin:0;}
</style>
